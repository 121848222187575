import axios from "axios";

const apiUrl = process.env.GATSBY_CM_API_URL;
const configPOST = (route, data) => {
	return {
		method: "post",
		url: apiUrl + route,
		data: data,
	};
};

export async function fetchAvailability(
	restaurantId,
	startDate,
	endDate,
	setData = function () {},
	setError = function () {}
) {
	var data = {
		restaurantId: restaurantId,
		startDate: startDate,
		endDate: endDate,
	};
	// console.log(data);

	var config = configPOST("/fetch-availability", data);
	return sendData(config, restaurantId, setData, setError);
}

export async function fetchHoursZones(
	restaurantId,
	date,
	setData = function () {},
	setError = function () {}
) {
	// console.log("Fetching zones & hours for: " + restaurantId);
	var data = {
		restaurantId: restaurantId,
		date: date,
	};

	// console.log(restaurant.coverManagerId);

	var config = configPOST("/fetch-hours-zones", data);
	return sendData(config, restaurantId, setData, setError);
}

export async function checkUnlockingCode(
	restaurantId,
	userCode,
	codeLock,
	setData = function () {},
	setError = function () {}
) {
	var data = {
		restaurantId: restaurantId,
		userCode: userCode,
		codeLock: codeLock,
	};

	var config = configPOST("/check-code", data);

	return axios(config) // Send request to Covermanager API
		.then((response) => {
			setData(response?.data);
		})
		.catch((error) => {
			setError(true);
			console.error("Error checking code: ", error);
		});
}

export async function makeReservation(
	restaurantForm,
	personDetails,
	customCommentary = undefined,
	setData = function () {},
	setError = function () {}
) {
	var data = {
		restaurantForm: restaurantForm,
		personDetails: personDetails,
		customCommentary: customCommentary,
	};

	var config = {
		method: "post",
		maxBodyLength: Infinity,
		url: apiUrl + "/send-reservation",
		headers: {
			"Content-Type": "application/json",
		},
		data: JSON.stringify(data),
	};

	// console.log(data);

	return axios(config) // Send request to Covermanager API
		.then((response) => {
			// Covermanager throws errors like this
			if (response.data.resp === 0) {
				setError(true);
				console.error("Error making reservation: ", response.data.status);
			} else {
				// If call is successful
				// console.log(response?.data);
				setData(response?.data);
			}
		})
		.catch((error) => {
			setError(true);
			console.error("Error making reservation: ", error);
		});
}

// export async function searchMail() {
// 	var data = {
// 		language: "spanish",
// 		restaurant: "restaurante-begin-pascualygenis",
// 		email: "luisherrero92@gmail.com",
// 	};

// 	console.log("SearchMail: ", data);

// 	// console.log(JSON.stringify(data));

// 	return axios({
// 		method: "post",
// 		maxBodyLength: Infinity,
// 		url: "https://www.covermanager.com/reservation/search_email_client",
// 		data: data,
// 	}).then((response) => {
// 		console.log(response);
// 	});
// }

async function sendData(
	config,
	restaurantId,
	setData = function () {},
	setError = function () {}
) {
	const errorFunction = (error) => {
		setError(true);
		console.error("Error posting data for " + restaurantId + ": ", error);
	};

	axios(config) // Send request to Covermanager API
		.then((response) => {
			// console.log(response);
			// If call is successful
			// console.log(response.data);
			setData((prevState) => ({
				...prevState,
				[restaurantId]: response.data,
			}));
		})
		.catch((error) => {
			errorFunction(error);
		});
}

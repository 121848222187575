import React from "react";

import "./localesGrid.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";

import PopUp from "../popUp/popUp";

import MaskSq1 from "../../svg/masks/maskSq1.svg";
import MaskSq2 from "../../svg/masks/maskSq2.svg";
import MaskSq3 from "../../svg/masks/maskSq3.svg";
import MaskSq4 from "../../svg/masks/maskSq4.svg";
import RestaurantPopUpContent from "../popUp/restaurantPopUpContent";

export default function LocalesGrid({ restaurants }) {
	const { t } = useTranslation();

	const [popUpContentI, setPopUpContentI] = React.useState(0);

	const restaurantArray = restaurants.nodes;
	// console.log(restaurantArray[popUpContentI - 1]);

	return (
		<div id="localesGridContainer">
			<div className="maskImports">
				<MaskSq1 />
				<MaskSq2 />
				<MaskSq3 />
				<MaskSq4 />
			</div>

			<PopUp
				popUpContentI={popUpContentI}
				setPopUpContentI={setPopUpContentI}
				array={restaurantArray}
			>
				<RestaurantPopUpContent
					restaurant={restaurantArray[popUpContentI - 1]}
				/>
			</PopUp>

			<div className="localesGrid">
				{restaurants.nodes.map(({ frontmatter }, i) => {
					return (
						<button
							key={frontmatter.title}
							className="localContainer"
							onClick={() => setPopUpContentI(i + 1)}
						>
							<GatsbyImage
								image={frontmatter.images[0].childImageSharp.gatsbyImageData}
								className="localImg"
								alt={t("El interior de un restaurante")}
							/>
							<h2>{frontmatter.title}</h2>
							<h3>{frontmatter.address1}</h3>
							<h3>{frontmatter.addressCity}</h3>
						</button>
					);
				})}
			</div>
		</div>
	);
}

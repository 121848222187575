import React from "react";

import "./bookingHourZoneSelector.scss";

import { Trans, useTranslation } from "react-i18next";

import { fetchHoursZones } from "../../utils/coverAPI";
// import { responseAvailabilityExample } from "../../utils/coverResponseAvailabilityDay";

import Accordion from "../accordion/accordion";
import { withPrefix } from "gatsby";
// import { Link } from "gatsby-plugin-react-i18next";

export default function BookingHourZoneSelector({
	restaurantsData,
	initialForm,
	selectedDay,
	availableDates,
	turns,
	restaurantForm,
	setRestaurantForm,
	setScreen,
}) {
	const { t } = useTranslation("bookingAPI");

	// const hoursZonesDev = responseAvailabilityExample; // dev placeholder. use restaurantesAvailability when ready
	const [hoursZones, setHoursZones] = React.useState(undefined);
	// console.log("hoursZones: ", hoursZones);
	// console.log(restaurantsData);

	// console.log(restaurantForm?.zone);

	const [zones, setZones] = React.useState(undefined);
	// console.log(zones);

	const [localsToFetch, setLocalsToFetch] = React.useState(0);
	const [errorFetchingData, setErrorFetchingData] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	// console.log("loading: " + loading);

	React.useEffect(() => {
		// Reset all variables
		setHoursZones(undefined);
		setLoading(true);
		setLocalsToFetch(0);
		setZones(undefined);
		setRestaurantForm(undefined);

		if (availableDates.has(selectedDay)) {
			setLocalsToFetch(1);
			// if date is available, only offer the chosen restaurant
			// console.log("Fetching hours for: " + initialForm.restaurant);
			fetchHoursZones(
				initialForm.restaurant,
				selectedDay,
				setHoursZones,
				setErrorFetchingData
			);
		} else {
			for (const restaurant in restaurantsData.nodes) {
				const coverManagerId =
					restaurantsData.nodes[restaurant].frontmatter.coverManagerId;
				if (coverManagerId) {
					// console.log(coverManagerId);
					if (coverManagerId !== initialForm.restaurant) {
						setLocalsToFetch((prevState) => prevState + 1);
						fetchHoursZones(
							coverManagerId,
							selectedDay,
							setHoursZones,
							setErrorFetchingData
						);
					}
				}
			}
		}

		// eslint-disable-next-line
	}, [selectedDay, initialForm]);

	React.useEffect(() => {
		setLoading(
			hoursZones && Object.keys(hoursZones).length === localsToFetch
				? false
				: true
		);

		const zonesTemp = {};

		for (const restaurant in hoursZones) {
			if (!zonesTemp[restaurant]) {
				zonesTemp[restaurant] = {};
			}
			for (const time in hoursZones[restaurant]?.availability?.people[
				initialForm.people
			]) {
				if (
					time >= turns[initialForm.turn].from &&
					time <= turns[initialForm.turn].to
				) {
					// console.log(
					// 	`Availability at ${time} in the zones `,
					// 	hoursZones[restaurant]?.availability?.people[initialForm.people][
					// 		time
					// 	].zones
					// );

					hoursZones[restaurant]?.availability?.people[initialForm.people][
						time
					].zones?.map((zone) => {
						// We use the name instead of the id so that same-name zones are not displayed double
						const zoneAsKey = String(zone.name);
						// console.log(restaurant, zone);
						if (!zonesTemp[restaurant][zone.name]) {
							// If it's a new zone, create its object
							zonesTemp[restaurant][zone.name] = { name: zone.name, times: [] };
						}
						// If it's not, check if the time is already in that zone under another id to avoid duplicates
						if (
							zonesTemp[restaurant][zone.name].times.find(
								(existingTime) => existingTime.time === time
							)
						) {
							// console.log("Duplicate detected");
							return null;
						}

						// Add new zone if the time is not duplicate
						return zonesTemp[restaurant][zoneAsKey].times.push({
							time: time,
							zoneId: zone.id,
						});
					});
				}
			}
			// console.log(restaurant, date, time);
		}

		// console.log("zonesTemp: ", zonesTemp);

		// Objetos con las zonas a unificar de cada restaurante, en orden de prioridad
		// También sirve para ordenar las zonas
		const mergeZones = {
			"restaurante-begin-cortes": {
				sala: ["Sala"],
				"taburete barra": ["Taburete Barra"],
				"terraza exterior en calle": ["TERRAZA EXTERIOR EN CALLE"],
			},
			"restaurante-begin-pascualygenis": {
				sala: ["SALA"],
				"terraza porche entrada": ["TERRAZA PORCHE ENTRADA"],
			},
			"restaurante-begincolon": {
				sala: ["SALA"],
			},
			"restaurante-begingandia": {
				sala: ["Sala", "Sala."], // Sala ofrece para +9, Sala. no
				"mesa alta con taburete": [
					"Mesa alta con taburete",
					"Mesa alta con taburete.",
				],
			},
			"restaurante-beginllull": {
				sala: ["SALA"],
				"mesa alta con taburete": ["Mesa Alta - Taburete"],
			},
		};

		var mergedZonesTemp = {};

		for (const restaurant in zonesTemp) {
			const zonesAlreadyMerged = [];
			if (!mergedZonesTemp[restaurant]) {
				mergedZonesTemp[restaurant] = {};
			}

			for (const groupName in mergeZones[restaurant]) {
				const mergedGroup = {
					name: groupName,
					times: [],
				};

				// We start with the first and fill the mergedGroup in order without overriding
				mergeZones[restaurant][groupName].map((zone) => {
					if (zonesTemp[restaurant][zone]) {
						zonesAlreadyMerged.push(zone);
						for (const time of zonesTemp[restaurant][zone]?.times) {
							if (
								mergedGroup.times.find(
									(existingTime) => existingTime?.time === time?.time
								)
							) {
								// If time exists, then do nothing
								// console.log(
								// 	"Duplicate time detected for " + restaurant + zone
								// );
							} else {
								// If time is not in the temp array, then add it
								mergedGroup?.times?.push(time);
							}
						}
					}
					return null;
				});

				// if the group isn't empty, then add it to the final array. Disabled to show "complete" on full zones
				// if (mergedGroup.times.length > 0) {
				mergedZonesTemp[restaurant][groupName] = mergedGroup;
				// }
				// console.log("mergedGroup: ", mergedGroup);
			}

			// console.log(zonesAlreadyMerged);

			// Lastly we add all the zones that aren't merged to the final array
			for (const zone in zonesTemp[restaurant]) {
				// look for the ones that have been not merged
				if (!zonesAlreadyMerged.includes(zone)) {
					mergedZonesTemp[restaurant][zone] = zonesTemp[restaurant][zone];
					// console.log(zone);
				}
			}
		}

		// console.log("mergedZonesTemp: ", mergedZonesTemp);
		setZones(mergedZonesTemp);

		// eslint-disable-next-line
	}, [initialForm, hoursZones]);

	const translateZone = (zoneName) => {
		// Añadir a mano nuevos nombres de sala
		const zonesTitles = {
			sala: t("sala"),
			"mesa alta con taburete": t("mesa alta con taburete"),
			"terraza porche entrada": t("terraza porche entrada"),
			"terraza exterior en calle": t("terraza exterior en calle"),
			"taburete barra": t("taburete barra"),
		};

		return zonesTitles[zoneName.toLowerCase()] || zoneName.toLowerCase();
	};

	return (
		<div className="availableHours">
			{!errorFetchingData && (
				<div>
					{loading && <div style={{ textAlign: "center" }}>Cargando...</div>}
					{zones && !loading && (
						<div className="zonesContainer">
							<p>
								<Trans>
									Debido a las condiciones meteorológicas, es posible que
									algunas reservas puedan verse afectadas. Gracias por vuestra
									comprensión y apoyo.
								</Trans>
							</p>
							{Object.keys(zones).map((restaurant) => {
								// Do not show restaurants without any availability
								var zoneWithAvailability = false;

								if (Object.keys(zones[restaurant]).length > 0) {
									Object.keys(zones[restaurant]).map((zone) => {
										if (zones[restaurant][zone].times.length > 0) {
											zoneWithAvailability = true;
										}
										return null;
									});
								}

								return (
									zoneWithAvailability && (
										<div
											key={"zonesAvailable_" + restaurant}
											className="restaurantContainer"
										>
											<h2>
												Begin{" "}
												{
													restaurantsData.nodes.find((rest) => {
														// console.log(rest.frontmatter.coverManagerId);
														// console.log(restaurant);
														return (
															rest.frontmatter.coverManagerId === restaurant
														);
													}).frontmatter.title
												}
											</h2>
											<h3 className="address">
												{
													restaurantsData.nodes.find((rest) => {
														return (
															rest.frontmatter.coverManagerId === restaurant
														);
													}).frontmatter.address1
												}
											</h3>
											{Object.keys(zones[restaurant]).map((zone) => {
												return (
													<div
														className="zoneContainer"
														key={restaurant + "_" + zone}
													>
														<Accordion
															title={translateZone(
																zones[restaurant][zone].name
															)}
														>
															<div>
																{zones[restaurant][zone].times.length === 0 ? (
																	<span>
																		<i>
																			<Trans>Completo</Trans>
																		</i>
																	</span>
																) : (
																	zones[restaurant][zone].times
																		.sort((a, b) => (a.time < b.time ? -1 : 1))
																		.map((time) => (
																			<button
																				key={
																					restaurant +
																					"_" +
																					zone +
																					"_" +
																					time.time
																				}
																				onClick={() =>
																					setRestaurantForm({
																						people: initialForm.people,
																						date: selectedDay,
																						time: time.time,
																						zone: {
																							id: time.zoneId,
																							name: translateZone(
																								zones[restaurant][zone].name
																							),
																						},
																						restaurant: restaurant,
																					})
																				}
																				className={`hour ${
																					restaurantForm?.restaurant ===
																						restaurant &&
																					restaurantForm?.zone?.id ===
																						time.zoneId &&
																					restaurantForm?.time === time.time
																						? "selected"
																						: ""
																				}`}
																			>
																				{time.time}
																			</button>
																		))
																)}
															</div>
														</Accordion>
													</div>
												);
											})}
										</div>
									)
								);
							})}
							{/* Aviso para fechas y rest específicos */}
							{
								// for 25/12 at lunch or 31/12 at dinner, only Christmas Menu avaiable
								(restaurantForm &&
									restaurantForm?.date === "2024-12-25" &&
									restaurantForm.time >= turns.lunch.from &&
									restaurantForm.time <= turns.lunch.to && (
										<p className="avisoFechas">
											<Trans>
												Sólo disponible Menu Navidad en este día y hora.
											</Trans>
											&ensp;
											<a
												href={withPrefix(
													"/menus#/" +
														restaurantsData.nodes.find(
															(restaurant) =>
																restaurant?.frontmatter?.coverManagerId ===
																restaurantForm.restaurant
														).frontmatter?.id +
														"/menuNavidad"
												)}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Trans>Ver menú</Trans>
											</a>
										</p>
									)) ||
									(restaurantForm &&
										restaurantForm?.date === "2024-12-31" &&
										restaurantForm.time >= turns.dinner.from &&
										restaurantForm.time <= turns.dinner.to && (
											<p className="avisoFechas">
												<Trans>
													Sólo disponible Menu Nochevieja en este día y hora.
												</Trans>
												&ensp;
												<a
													href={withPrefix(
														"/menus#/" +
															restaurantsData.nodes.find(
																(restaurant) =>
																	restaurant?.frontmatter?.coverManagerId ===
																	restaurantForm.restaurant
															).frontmatter?.id +
															"/menuNochevieja"
													)}
													target="_blank"
													rel="noopener noreferrer"
												>
													<Trans>Ver menú</Trans>
												</a>
											</p>
										)) ||
									([
										"2024-12-01",
										"2024-12-02",
										"2024-12-03",
										"2024-12-04",
										"2024-12-05",
										"2024-12-06",
										"2024-12-07",
										"2024-12-08",
										"2024-12-09",
										"2024-12-10",
										"2024-12-11",
										"2024-12-12",
										"2024-12-13",
										"2024-12-14",
										"2024-12-15",
										"2024-12-16",
										"2024-12-17",
										"2024-12-18",
										"2024-12-19",
										"2024-12-20",
										"2024-12-21",
										"2024-12-22",
										"2024-12-23",
										"2024-12-24",
										"2024-12-25",
										"2024-12-26",
										"2024-12-27",
										"2024-12-28",
										"2024-12-29",
										"2024-12-30",
										"2024-12-31",
										"2025-01-01",
										"2025-01-02",
										"2025-01-03",
										"2025-01-04",
										"2025-01-05",
										"2025-01-06",
									].includes(restaurantForm?.date) && (
										<p className="avisoFechas">
											<Trans>
												Desde el 1 de diciembre al 6 de enero los menús de grupo
												y menú degustación no estarán disponibles. En su lugar
												se podrá escoger el menú degustación navideño.
											</Trans>
											&ensp;
											<a
												href={withPrefix(
													"/menus#/" +
														restaurantsData.nodes.find(
															(restaurant) =>
																restaurant?.frontmatter?.coverManagerId ===
																restaurantForm.restaurant
														).frontmatter?.id +
														"/menuDegustacionNavidad"
												)}
												target="_blank"
												rel="noopener noreferrer"
											>
												<Trans>Ver menú</Trans>
											</a>
										</p>
									))
							}
							<button
								className="nextButton underline"
								disabled={!restaurantForm}
								onClick={() => {
									if (
										(restaurantForm &&
											restaurantForm?.date === "2024-12-25" &&
											restaurantForm.time >= turns.lunch.from &&
											restaurantForm.time <= turns.lunch.to) ||
										(restaurantForm &&
											restaurantForm?.date === "2024-12-31" &&
											restaurantForm.time >= turns.dinner.from &&
											restaurantForm.time <= turns.dinner.to)
									) {
										setScreen(3);
									} else {
										setScreen(1);
									}
								}}
								aria-label={t("Continuar")}
							>
								<span>
									<Trans ns="bookingAPI">Continuar</Trans>
								</span>
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

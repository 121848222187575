import React from "react";

import "./bookingCalendar.scss";

import moment from "moment";
import MonthView from "react-calendar";
import { useI18next } from "gatsby-plugin-react-i18next";
import { navigate } from "gatsby";

export default function BookingCalendar({
	allRestaurants,
	minDate,
	maxDate,
	selectedDay,
	setSelectedDay,
	initialForm,
	restaurantsAvailability,
	availableDates,
	setAvailableDates,
	semiAvailableDates,
	setSemiAvailableDates,
	turns,
}) {
	const { language } = useI18next();
	// console.log("Available: ", availableDates);
	// console.log("Semiavailable: ", semiAvailableDates);

	React.useEffect(() => {
		// Reset the dates states
		setAvailableDates(new Set());
		setSemiAvailableDates(new Set());
		const availableDatesTemp = new Set();
		const semiAvailableDatesTemp = new Set();

		// console.log("This should only run on initialForm change");

		// console.log(allRestaurants);

		// A function to iterate all the dates for a restaurant in the response object and assign them to a set
		const assignDates = async (restaurant, targetSet) => {
			// console.log(restaurant);
			for (const date in restaurantsAvailability[restaurant]?.calendar) {
				// console.log(date);
				// If date is already assigned, do not evaluate. FOR THIS TO WORK PROPERLY THE AVAILABLE SET MUST ALWAYS BE FILLED BEFORE THE SEMIAVAILABLE SET
				if (
					!availableDatesTemp.has(date) &&
					!semiAvailableDatesTemp.has(date)
				) {
					if (restaurantsAvailability[restaurant]?.calendar?.[date]?.people) {
						for (const time in restaurantsAvailability[restaurant]?.calendar?.[
							date
						]?.people[initialForm.people]) {
							// console.log(restaurant, date, time);
							if (
								time >= turns[initialForm.turn].from &&
								time <= turns[initialForm.turn].to &&
								restaurantsAvailability[restaurant]?.calendar?.[date]?.people[
									initialForm.people
								][time]
							) {
								// console.log(
								// 	`Availability at ${restaurant} for ${initialForm.people} people on ${date} ${time}`
								// );
								targetSet.add(date);
								break;
							}
						}
					}
				}
			}
			return;
		};

		if (initialForm) {
			// First lets check the dates for the selected restaurant and assign them as available
			// console.log(initialForm.restaurant);
			assignDates(initialForm.restaurant, availableDatesTemp).then(() => {
				// console.log(initialForm.restaurant);
				setAvailableDates(availableDatesTemp);
				// Then we check the other restaurants and assign them as semiavailable
				if (Object.keys(restaurantsAvailability) === 1) {
					// If there is only one restaurant to fetch, then there are no semiAvailableDates
					setSemiAvailableDates(new Set());
				} else {
					for (const restaurant in restaurantsAvailability) {
						// console.log(restaurantsAvailability);
						if (restaurant !== initialForm.restaurant) {
							assignDates(restaurant, semiAvailableDatesTemp).then(() => {
								setSemiAvailableDates(semiAvailableDatesTemp);
							});
						}
					}
				}
			});
		}

		// console.log(restaurantsAvailability);
		// console.log(initialForm);

		// eslint-disable-next-line
	}, [initialForm]);

	const formatShortWeekday = (locale, date) => {
		if (!date) {
			return "";
		}
		// console.log(locale);
		const weekdaysByLang = {
			es: ["D", "L", "M", "Mi", "J", "V", "S"],
			en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		};
		return (
			weekdaysByLang[locale][date.getDay()] || weekdaysByLang.en[date.getDay()]
		);
	};

	const handleDaySelect = (date) => {
		setSelectedDay(moment(date).format("YYYY-MM-DD"));
		navigate("#zoneSelect");
	};

	return (
		<MonthView
			minDetail="month"
			maxDetail="month"
			value={minDate}
			minDate={minDate}
			maxDate={maxDate}
			locale={language}
			formatShortWeekday={formatShortWeekday}
			onClickDay={(date) => handleDaySelect(date)}
			tileClassName={({ date, view }) => {
				const formatDate = moment(date).format("YYYY-MM-DD");

				const classNames = [];

				if (formatDate === selectedDay) {
					classNames.push("selected");
				}

				if (allRestaurants) {
					if (semiAvailableDates.has(moment(date).format("YYYY-MM-DD"))) {
						classNames.push("available");
					}
				} else {
					if (availableDates.has(moment(date).format("YYYY-MM-DD"))) {
						classNames.push("available");
					}

					if (semiAvailableDates.has(moment(date).format("YYYY-MM-DD"))) {
						classNames.push("semiavailable");
					}
				}

				return classNames.join(" ") || "unavailable";
			}}
		/>
	);
}

import React from "react";

import "./restaurantPopUpContent.scss";
import { Trans } from "react-i18next";

import Separador from "../../svg/lineaSeparador.svg";
import Carousel from "../Carousel/carousel";
import { GatsbyImage } from "gatsby-plugin-image";

import MaskSq1 from "../../svg/masks/maskSq1.svg";

export default function RestaurantPopUpContent({ restaurant }) {
	const data = restaurant.frontmatter;

	const initialSlide = {
		profundidadesMarinas: 3,
		huerta: 6,
		jungla: 3,
	};

	// console.log(data.id);
	return (
		<div className="restaurantPopUpContainer">
			<MaskSq1 />

			<div className="imagesCarousel">
				<Carousel
					sliderSettings={{
						dots: true,
						infinite: true,
						autoplay: true,
						autoplaySpeed: 3000,
					}}
					initialSlidePosition={initialSlide[data.id] || 0}
				>
					{data.images.map((image, i) => (
						<GatsbyImage
							image={image.childImageSharp.gatsbyImageData}
							alt=""
							key={"restImg" + i}
						/>
					))}
				</Carousel>
			</div>

			<div className="details">
				<h3>
					<Trans>Nuestros espacios</Trans>
				</h3>
				<h1>Begin {data.title}</h1>
				<p>{data.subtitle}</p>
				<Separador />
				<h3>
					<Trans>Cómo llegar</Trans>
				</h3>
				<a href={data.addressLink} target="_blank" rel="noopener noreferrer">
					{data.address1 + ", " + data.addressPostal + " " + data.addressCity}
				</a>

				<h3>
					<Trans>Horarios</Trans>
				</h3>
				<div className="hours">
					{data.hours.brunch && (
						<div>
							<div>
								<p>
									<Trans>Brunch</Trans>
								</p>
							</div>

							<div>
								{data.hours.brunch.map((hoursLine, i) => (
									<p key={"brunch" + i}>{hoursLine} h.</p>
								))}
							</div>
						</div>
					)}
					{data.hours.lunch && (
						<div>
							<div>
								<p>
									<Trans>Comida</Trans>
								</p>
							</div>

							<div>
								{data.hours.lunch.map((hoursLine, i) => (
									<p key={"lunch" + i}>{hoursLine} h.</p>
								))}
							</div>
						</div>
					)}
					{data.hours.dinner && (
						<div>
							<div>
								<p>
									<Trans>Cena</Trans>
								</p>
							</div>

							<div>
								{data.hours.dinner.map((hoursLine, i) => (
									<p key={"dinner" + i}>{hoursLine} h.</p>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

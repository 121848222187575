// main imports
import * as React from "react";

// plugin imports
import Slider from "react-slick";

// style imports
import "./slick.css";
import "./slick-theme.css";
import "./carousel.scss";

// svg imports

// components imports

// component
export default function Carousel({
	className = "",
	sliderSettings = null,
	initialSlidePosition = null,
	children,
}) {
	const sliderRef = React.useRef(null);
	const containerRef = React.useRef(null);

	// const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
	// 	<span {...props}>{children}</span>
	// );

	const settings = {
		dots: false,
		arrows: false,
		infinite: false,
		speed: 500,
		centerMode: true,
		centerPadding: 0,
		autoplay: false,
		autoplaySpeed: 10000,
		cssEase: "ease",
		useTransform: true,
		touchThreshold: 10,
		variableWidth: true,
		// swipeToSlide: true, // it's buggy, jumps back 2 slides when sliding left
		...sliderSettings, // we add the propped settings
	};

	// Workaround to avoid initialslide bug
	const [hasSetPosition, setHasSetPosition] = React.useState(false);
	React.useEffect(() => {
		if (initialSlidePosition && sliderRef.current && !hasSetPosition) {
			sliderRef.current?.slickGoTo(initialSlidePosition);
			setHasSetPosition(true);
		}
	}, [initialSlidePosition, hasSetPosition, sliderRef]);

	// Enable scroll navigation
	React.useEffect(() => {
		if (containerRef === null || sliderRef === null) return null;
		var scrollEnabled = true;

		const handleScroll = (e) => {
			setTimeout(() => {
				scrollEnabled = true;
			}, 1000);
			// console.log(scrollEnabled, e);
			if (scrollEnabled) {
				e.preventDefault();
				scrollEnabled = false;

				if (e.wheelDeltaX > 10) {
					return sliderRef?.current?.slickPrev();
				}
				if (e.wheelDeltaX < -10) {
					return sliderRef?.current?.slickNext();
				}
			}
		};

		containerRef.current.addEventListener("wheel", (e) => handleScroll(e));
		return containerRef.current.removeEventListener("wheel", (e) =>
			handleScroll(e)
		);
	}, []);

	return (
		<div className={`carousel ${className}`} ref={containerRef}>
			<Slider {...settings} ref={sliderRef}>
				{children}
			</Slider>
		</div>
	);
}
